import Vue from 'vue'
import VueRouter from 'vue-router'
import Token from "@/token";
import Users from "@/users";
import eventBus from "@/events";
import Constants from "@/constants";

Vue.use(VueRouter)

const UserList = () => import('@/users/UserList');
const ProfileList = () => import('@/users/profiles/ProfileList');
const UserForm = () => import('@/users/UserForm');
const ProfileForm = () => import('@/users/profiles/ProfileForm');
const UserProfile = () => import('@/users/UserProfile');
const SessionList = () => import('@/users/sessions/SessionList');
const Login = () => import('../views/Login.vue');
const BasePage = () => import('@/views/BasePage');
const ApplicationLogList = () => import('@/log/ApplicationLogList');
const Recovery = () => import('@/views/Recovery');
const Reset = () => import('@/views/Reset');

const TravelTypeForm = () => import('@/travel-types/TravelTypeForm');
const TravelTypeList = () => import('@/travel-types/TravelTypeList');
const TurnForm = () => import('@/turns/TurnForm');
const TurnList = () => import('@/turns/TurnList');
const RouteForm = () => import('@/routes/RouteForm');
const RouteList = () => import('@/routes/RouteList');
const DriverForm = () => import('@/drivers/DriverForm');
const DriverList = () => import('@/drivers/DriverList');
const BusForm = () => import('@/buses/BusForm');
const BusList = () => import('@/buses/BusList');
const CompanyForm = () => import('@/companies/CompanyForm');
const CompanyList = () => import('@/companies/CompanyList');
const DieselControlForm = () => import('@/diesel-control/DieselControlForm');
const DieselControlList = () => import('@/diesel-control/DieselControlList');
const TravelProgrammingForm = () => import('@/travel-programmings/TravelProgrammingForm');
const TravelProgrammingList = () => import('@/travel-programmings/TravelProgrammingList');
const TravelList = () => import('@/travels/TravelList');
const HomeComponent = () => import('@/home/HomeComponent');
const PayrollReport = () => import('@/reports/PayrollReport');
const CollectionReport = () => import('@/reports/CollectReport');
const BusReport = () => import('@/reports/BusReport');

const routes = [
    {
        path: '/login',
        name: 'LOGIN_PAGE_TITLE',
        component: Login
    },
    {
        path: '',
        name: 'HOME_PAGE_TITLE',
        component: BasePage,
        children: [
            {path: '', name: 'HOME_PAGE', component: HomeComponent},
        ]
    },
    {
        path: '/recovery',
        name: 'PASSWORD_RECOVERY_PAGE_TITLE',
        component: Recovery
    },
    {
        path: '/password-reset',
        name: 'PASSWORD_RESET_PAGE_TITLE',
        component: Reset
    },
    {
        path: '/users',
        component: BasePage,
        children: [
            {path: '', name: 'USER_LIST_PAGE_TITLE', component: UserList},
            {path: 'register', name: 'USER_REGISTER_TITLE', component: UserForm},
            {path: ':id/edit', name: 'USER_EDIT_TITLE', component: UserForm}
        ]
    },
    {
        path: '/profile',
        component: BasePage,
        children: [
            {path: '', name: 'PROFILE_PAGE_TITLE', component: UserProfile},
        ]
    },
    {
        path: '/profiles',
        component: BasePage,
        children: [
            {path: '', name: 'PROFILE_LIST_PAGE_TITLE', component: ProfileList},
            {path: 'register', name: 'PROFILE_REGISTER_TITLE', component: ProfileForm},
            {path: ':id/edit', name: 'PROFILE_EDIT_TITLE', component: ProfileForm}
        ]
    },
    {
        path: '/sessions',
        component: BasePage,
        children: [
            {path: '', name: 'SESSION_LIST_PAGE_TITLE', component: SessionList}
        ]
    },
    {
        path: '/logs',
        component: BasePage,
        children: [
            {path: '', name: 'LOG_LIST_PAGE_TITLE', component: ApplicationLogList}
        ]
    },
    {
        path: '/travelTypes',
        component: BasePage,
        children: [
            {path: '', name: 'TRAVEL_TYPE_LIST_PAGE_TITLE', component: TravelTypeList},
            {path: 'register', name: 'TRAVEL_TYPE_REGISTER_TITLE', component: TravelTypeForm},
            {path: ':id/edit', name: 'TRAVEL_TYPE_EDIT_TITLE', component: TravelTypeForm}
        ]
    }, {
        path: '/turns',
        component: BasePage,
        children: [
            {path: '', name: 'TURN_LIST_PAGE_TITLE', component: TurnList},
            {path: 'register', name: 'TURN_REGISTER_TITLE', component: TurnForm},
            {path: ':id/edit', name: 'TURN_EDIT_TITLE', component: TurnForm}
        ]
    }, {
        path: '/routes',
        component: BasePage,
        children: [
            {path: '', name: 'ROUTE_LIST_PAGE_TITLE', component: RouteList},
            {path: 'register', name: 'ROUTE_REGISTER_TITLE', component: RouteForm},
            {path: ':id/edit', name: 'ROUTE_EDIT_TITLE', component: RouteForm}
        ]
    }, {
        path: '/drivers',
        component: BasePage,
        children: [
            {path: '', name: 'DRIVER_LIST_PAGE_TITLE', component: DriverList},
            {path: 'register', name: 'DRIVER_REGISTER_TITLE', component: DriverForm},
            {path: ':id/edit', name: 'DRIVER_EDIT_TITLE', component: DriverForm}
        ]
    }, {
        path: '/buses',
        component: BasePage,
        children: [
            {path: '', name: 'BUS_LIST_PAGE_TITLE', component: BusList},
            {path: 'register', name: 'BUS_REGISTER_TITLE', component: BusForm},
            {path: ':id/edit', name: 'BUS_EDIT_TITLE', component: BusForm}
        ]
    }, {
        path: '/companies',
        component: BasePage,
        children: [
            {path: '', name: 'COMPANY_LIST_PAGE_TITLE', component: CompanyList},
            {path: 'register', name: 'COMPANY_REGISTER_TITLE', component: CompanyForm},
            {path: ':id/edit', name: 'COMPANY_EDIT_TITLE', component: CompanyForm}
        ]
    }, {
        path: '/dieselControl',
        component: BasePage,
        children: [
            {path: '', name: 'DIESEL_CONTROL_LIST_PAGE_TITLE', component: DieselControlList},
            {path: 'register', name: 'DIESEL_CONTROL_REGISTER_TITLE', component: DieselControlForm},
            {path: ':id/edit', name: 'DIESEL_CONTROL_EDIT_TITLE', component: DieselControlForm}
        ]
    }, {
        path: '/travel-programmings',
        component: BasePage,
        children: [
            {path: '', name: 'TRAVEL_PROGRAMMING_LIST_PAGE_TITLE', component: TravelProgrammingList},
            {path: 'register', name: 'TRAVEL_PROGRAMMING_REGISTER_TITLE', component: TravelProgrammingForm},
            {path: ':id/edit', name: 'TRAVEL_PROGRAMMING_EDIT_TITLE', component: TravelProgrammingForm}
        ]
    },
    {
        path: '/travels',
        component: BasePage,
        children: [
            {path: '', name: 'TRAVEL_LIST_PAGE_TITLE', component: TravelList}
        ]
    },
    {
        path: '/reports',
        component: BasePage,
        children: [
            {path: 'payroll', name: 'PAYROLL_REPORT_PAGE_TITLE', component: PayrollReport},
            {path: 'collection', name: 'COLLECTION_REPORT_PAGE_TITLE', component: CollectionReport},
            {path: 'bus', name: 'BUS_REPORT_PAGE_TITLE', component: BusReport}
        ]
    }
]

const router = new VueRouter({
    routes
});

router.beforeResolve(async (to, from, next) => {
    let permission;
    if (Token.isAuthenticated()) {
        let user = Users.currentUser;
        if (to.name === 'HOME_PAGE') {
            if (user && user.data && user.data.defaultPage) {
                next({name: user.data.defaultPage});
            }
        }
        switch (to.name) {
            case 'USER_LIST_PAGE_TITLE':
                permission = 'USER_LIST_ALL_PERMISSION';
                break;
            case 'SESSION_LIST_PAGE_TITLE':
                permission = 'SESSION_LIST_ALL_PERMISSION';
                break;
            case 'PROFILE_LIST_PAGE_TITLE':
                permission = 'PROFILE_LIST_ALL_PERMISSION';
                break;
            default:
                permission = undefined;
                break;
        }
        if (permission && !Token.hasPermission(permission)) {
            eventBus.$emit(Constants.EVENT_WHEN_MESSAGE_DISPLAY, 'USER_HAS_NO_PERMISSION', {page: to.name}, 'warning', undefined)
            next(from);
        } else {
            next();
        }
    } else {
        console.log('Not authenticated')
        const exceptions = [null, 'LOGIN_PAGE_TITLE', 'PASSWORD_RECOVERY_PAGE_TITLE', 'PASSWORD_RESET_PAGE_TITLE'];
        const name = to.name;
        if (exceptions.indexOf(name) === -1) {
            next({name: 'LOGIN_PAGE_TITLE'});
        } else {
            next();
        }
    }
});

export default router
