import VueI18n from 'vue-i18n';
import Vue from "vue";

const messages = {
    es: {
        TRAVEL_PAID_LABEL: 'tipo de pago',
        TRAVEL_IS_PAID_LABEL: 'viajes cobrados',
        TRAVEL_NOT_PAID_LABEL: 'viajes no cobrados',
        TRAVEL_ALL_LABEL: 'todos los viajes',
        TOTAL_BY_TRAVEL_TYPE: 'total del tipo de viaje {travelType}',
        BUS_REPORT_TITLE: 'reporte de viajes por camion {general}',
        BUS_REPORT_TITLE_GENERAL: 'general',
        BUS_REPORT_FLOAT_NUMBER_LABEL: 'no. flotilla',
        BUS_REPORT_ECONOMIC_LABEL: 'economico',
        BUS_REPORT_ROUTE_LABEL: 'recorrido',
        BUS_REPORT_MONDAY_LABEL: 'lunes',
        BUS_REPORT_TUESDAY_LABEL: 'martes',
        BUS_REPORT_WEDNESDAY_LABEL: 'miercoles',
        BUS_REPORT_THURSDAY_LABEL: 'jueves',
        BUS_REPORT_FRIDAY_LABEL: 'viernes',
        BUS_REPORT_SATURDAY_LABEL: 'sabado',
        BUS_REPORT_SUNDAY_LABEL: 'domingo',
        BUS_REPORT_TOTAL_LABEL: 'total',
        BUS_FILTER_LABEL: 'filtrar por camion (economico)',
        ALL_BUSSES_LABEL: '-- Todos los camiones --',
        BUS_REPORT: 'reporte de viajes por camion',
        BUS_REPORT_PAGE_TITLE: 'reporte de viajes por camion',
        PAYROLL_REPORT_ECO_LABEL: 'economico',
        TRAVEL_PARENT_LABEL: 'programacion',
        TRAVEL_NEXT_DAY: 'Este viaje forma parte de una programacion del dia anterior, se contabilizara el dia {date}',
        TURN_NEXT_DAY_IN_LABEL: 'contabilizar dia anterior (entrada)',
        TURN_NEXT_DAY_OUT_LABEL: 'contabilizar dia anterior (salida)',
        PASS_DELETE_LABEL: 'eliminar',
        TRAVEL_DELETE_WARNING: 'ADVERTENCIA! desea eliminar el viaje numero {number}? (esta accion no puede deshacerse)',
        TRAVEL_DELETED_OK: 'Viaje {number} eliminado',
        TRAVEL_DELETED_ERROR: 'Error al eliminar el viaje {number}.',
        CLOSE_TRAVELS_WARNING: 'ADVERTENCIA! esta a punto de cerrar todos los viajes antes de la fecha seleccionada, este proceso no puede deshacerse y para poder editar viajes cerrados sera necesario tener un permiso especial, para continuar presione OK (boton azul) de lo contrario presione Cancel (boton gris)',
        TRAVELS_CLOSED_OK: 'Cierre de viajes completo',
        CLOSE_TRAVEL_DATE: 'fecha de cierre',
        CLOSE_TRAVELS_MODAL_TITLE: 'cierre de viajes',
        SELECT_ONE_TRAVEL_TYPE: '-- seleccione un tipo de viaje --',
        TRAVELS_CLOSE_LABEL: 'realizar cierre',
        PASS_CHANGED_OK: 'viaje actualizado',
        EDIT_TRAVEL_TITLE: 'editar viaje',
        PASS_EDIT_LABEL: 'editar',
        PASS_TRAVEL_TYPE_LABEL: 'tipo de viaje',
        PASS_CHANGE_TRAVEL_TYPE_LABEL: 'cambiar tipo de viaje',
        FORMS_SELECT_ONE_TRAVEL_TYPE: '-- seleccione un tipo de viaje --',
        PASS_PAID_OK_CHANGED: 'bandera de cobro actualizada.',
        PASS_PAID_LABEL: 'se cobrara',
        PASS_PAID_CHANGE_TITLE: 'cambiar si el viaje se cobrara',
        CONFIRM_FINAL_PASS_MESSAGE: 'Esta seguro que desea dar el ultimo pase? (esto cerrara el viaje y lo contabilizara en reportes)',
        FORMS_SELECT_ONE_DIRECTION: 'entrada/salida',
        COLLECT_REPORT_TITLE: 'Reporte de cobro {general}',
        PAYROLL_TOTAL_LABEL: 'total de viajes empresa',
        TURN_FILTER_LABEL: 'Filtrar por turno',
        TRAVEL_TYPE_FILTER_LABEL: 'Filtrar por tipo de filtro',
        ALL_TURNS_LABEL: '-- todos los turnos --',
        ALL_TRAVEL_TYPES_LABEL: '-- todos los tipos de viaje --',
        PAYROLL_REPORT_TITLE_GENERAL: 'general',
        PAYROLL_REPORT_ASSOCIATE_LABEL: 'socios',
        REPORTS_NO_DATA: 'No hay datos para mostrar',
        ALL_COMPANIES_LABEL: '-- Todas las empresas --',
        ALL_DRIVERS_LABEL: '-- Todos los choferes --',
        COMPANY_FILTER_LABEL: 'filtrar por empresa',
        DRIVER_FILTER_LABEL: 'filtrar por chofer',
        LAST_NAME_FILTER_LABEL: 'filtrar por apellido paterno',
        SELECT_ONE_DRIVER: 'seleccione un chofer',
        SELECT_ONE_BUS: 'seleccione un camion',
        SELECT_ONE_TURN: 'seleccione un turno',
        SELECT_ONE_COMPANY: 'seleccione una empresa',
        SELECT_ONE_ROUTE: 'seleccione un recorrido',
        CONFIRM_DELETE_TRAVELS_MESSAGE: 'esta seguro que desea eliminar los viajes de esta programacion? (para volver a ver los pases debera generar los viajes manualmente)',
        TRAVEL_PROGRAMMING_DESTROY_TRAVELS_PERMISSION: 'permiso para eliminar los viajes (no modificados o iniciados) de una programacion',
        TRAVELS_DESTROY_LABEL: 'eliminar viajes',
        TRAVELS_DESTROYED_OK: 'viajes eliminados correctamente.',
        TRAVELS_DESTROYED_ERROR: 'ocurrio un error al eliminar los viajes.',
        TRAVEL_PROGRAMMING_CREATE_TRAVELS_PERMISSION: 'permiso para generar manualmente los pases de una programacion',
        ERROR_EVENTS_MUST_HAVE_TO: 'Advertencia! no se han definido horarios de entrada, el pase de entrada no se generara.',
        ERROR_EVENTS_MUST_HAVE_FROM: 'Advertencia! no se han definido horarios de salida, el pase de salida no se generara.',
        PAYROLL_REPORT_TITLE: 'Reporte de nómina {general}',
        PAYROLL_REPORT_BUS_LABEL: 'No. flotilla',
        PAYROLL_REPORT_TOTAL_LABEL: 'total viajes',
        PAYROLL_REPORT_ROUTE_LABEL: 'ruta',
        PAYROLL_REPORT_COMPANY_LABEL: 'empresa',
        PAYROLL_REPORT_DRIVER_LABEL: 'nombre del empleado',
        PAYROLL_REPORT_PAYMENT_LABEL: 'pago',
        PAYROLL_REPORT_MONDAY_LABEL: 'l',
        PAYROLL_REPORT_TUESDAY_LABEL: 'm',
        PAYROLL_REPORT_WEDNESDAY_LABEL: 'm',
        PAYROLL_REPORT_THURSDAY_LABEL: 'j',
        PAYROLL_REPORT_FRIDAY_LABEL: 'v',
        PAYROLL_REPORT_SATURDAY_LABEL: 's',
        PAYROLL_REPORT_SUNDAY_LABEL: 'd',
        PAYROLL_REPORT_PAGE_TITLE: 'reporte de nomina',
        COLLECTION_REPORT_PAGE_TITLE: 'reporte de cobro',
        QUERY_LABEL: 'consultar',
        TRAVEL_NUMBER_LABEL: 'folio',
        ROUTE_EVENTS_DIRECTION_LABEL: 'entrada/salida',
        TRAVELS_GENERATED_OK: '{travels} viajes generados correctamente',
        TRAVELS_GENERATED_ERROR: 'ocurrio un error al generar los viajes',
        TRAVELS_GENERATE_LABEL: 'generar viajes',
        CHANGED_PAID_FLAG: 'se ha cambiado el ',
        PASS_CHANGE_TO_NON_PAID_LABEL: 'Cambiar a no cobrable',
        PASS_CHANGE_TO_PAID_LABEL: 'Cambiar a cobrable',
        TRAVEL_WILL_BE_CHARGED: 'se cobrara',
        TRAVEL_WILL_NOT_BE_CHARGED: 'no se cobrara',
        TRAVELS_SHOW_CLOSED: 'mostrar los viajes terminados',
        FORMS_SELECT_ONE_TURN: '-- Todos los turnos --',
        FORMS_SELECT_ONE_COMPANY: '-- Todas las empresas --',
        FORMS_SELECT_ONE_BUS: '-- Todos los camiones --',
        FORMS_SELECT_ONE_DRIVER: '-- Todos los choferes --',
        NO_TRAVELS_FOUND: 'No se encontraron viajes programados',
        BUS_GPS_NUMBER_LABEL: 'numero de gps',
        BUS_POLIZA_PHOTO_LABEL: 'imagen poliza',
        BUS_TYPE_INTERN: 'INTERNO',
        BUS_TYPE_EXTERN: 'EXTERNO',
        DRIVER_LICENSE_PHOTO_LABEL: 'foto de la licencia',
        FORMS_ERROR_FIELD_VALIDDATE: 'debe especificar una fecha valida.',
        TRAVEL_PROGRAMMINGS: 'programaciones de viaje',
        TRAVEL_PROGRAMMING: 'programacion de viaje',
        TRAVEL_TO: 'entrada',
        TRAVEL_FROM: 'salida',
        TRAVEL_PROGRAMMING_DETAIL_COMPANY: 'empresa',
        TRAVEL_PROGRAMMING_DETAIL_TURN: 'turno',
        TRAVEL_PROGRAMMING_DETAIL_TRAVEL_TYPE: 'tipo de recorrido',
        TRAVEL_PROGRAMMING_DETAIL_ROUTE: 'recorrido',
        TRAVEL_PROGRAMMING_DETAIL_BOTH: 'entrada y salida',
        TRAVEL_PROGRAMMING_DETAIL_TRAVEL_DIRECTION: 'entrada o salida',
        TRAVEL_PROGRAMMING_DETAIL_ACCOUNT: 'contabilizar dia anterior',
        TRAVEL_DIRECTION_LABEL: 'entrada/salida',
        TRAVEL_PROGRAMMING_TRAVEL_TO: 'entrada',
        TRAVEL_PROGRAMMING_TRAVEL_FROM: 'salida',
        TRAVEL_BOTH_LABEL: 'entrada y salida',
        TRAVEL_DETAILS_TITLE: 'detalles de la programacion',
        INVALID_FILE_EXTENSION: 'extension de archivo invalida',
        ROUTE_EVENTS_DETAILS: 'eventos de la ruta',
        FORMS_DETAIL_ADD: 'agregar evento',
        ROUTE_EVENTS_NAME_LABEL: 'nombre del evento',
        ROUTE_EVENTS_TIME_LABEL: 'horario',
        ROUTE_EVENTS_COLOR_LABEL: 'color',
        MODULES: 'modulos',
        REPORTS: 'reportes',
        PAYROLL_REPORT: 'reporte de nómina',
        COLLECTION_REPORT: 'reporte de cobro',
        FILTER_OPTIONS_ALL_LABEL: 'tod{gender}s l{gender}s {catalog}',
        FORMS_SELECT_ONE: '-- seleccione uno --',
        ROUTE_COMPANY_LABEL: 'empresa',
        ROUTE_TURN_LABEL: 'turno',
        TURN_COMPANY_LABEL: 'empresa',
        DRIVER_SEX_MALE: 'masculino',
        DRIVER_SEX_FEMALE: 'femenino',
        DRIVER_ALL_TYPES: 'Todos los tipos',
        DRIVER_CIVIL_STATUS_SINGLE: 'soltero',
        DRIVER_CIVIL_STATUS_FREE_UNION: 'union libre',
        DRIVER_CIVIL_STATUS_MARRIED: 'casado',
        DRIVER_CIVIL_STATUS_DIVORCED: 'divorciado',
        DRIVER_CIVIL_STATUS_SEPARATED: 'separado',
        DRIVER_CIVIL_STATUS_WIDOW: 'viudo',
        DRIVER_TYPE_EMPLOYEE: 'empleado',
        DRIVER_TYPE_ASSOCIATED: 'socio',
        DRIVER_TYPE_ASSOCIATE: 'socio',
        DRIVER_PAYMENT_PAYROLL: 'nomina',
        DRIVER_PAYMENT_CASH: 'efectivo',
        DRIVER_PERSONAL_DATA_TITLE: 'Datos personales',
        DRIVER_ADDRESS_DATA_TITLE: 'Domicilio',
        DRIVER_DOCUMENTATION_DATA_TITLE: 'Documentación laboral',
        DRIVER_DOCUMENT_REGISTRY_TITLE: 'Registro de papelería',
        DRIVER_HEALTH_DATA_TITLE: 'Estado de salud',
        DRIVER_REGISTRY_TITLE: 'Registro',
        DRIVER_COMPANY_LABEL: 'Empresa',
        DRIVER_FULL_NAME_LABEL: 'Nombre completo',
        BUS_PHOTOS_TITLE: 'fotografías',
        BUS_NEXT_TUNE_UP_LABEL: 'Sig. Tune up',
        BUS_COMPANY_LABEL: 'Empresa',
        TRAVEL_REPEAT_TITLE: 'repetir programación',
        TRAVEL_ID_LABEL: 'ID Viaje',
        TRAVEL_COMPANY_LABEL: 'empresa',
        TRAVEL_TURN_LABEL: 'turno',
        TRAVEL_ROUTE_LABEL: 'recorrido',
        TRAVEL_DRIVER_LABEL: 'chofer',
        TRAVEL_DRIVER_TYPE_LABEL: 'tipo de chofer',
        TRAVEL_BUS_LABEL: 'camion',
        TRAVEL_TYPE_LABEL: 'tipo de viaje',
        TRAVEL_PAYMENT_TYPE_LABEL: 'tipo de pago',
        TRAVEL_HAS_EXIT_LABEL: 'Tendra salida?',
        TRAVEL_IS_FINISHED_LABEL: 'Terminado',
        TRAVEL_DIESEL_PAYROLL_LABEL: 'nomina/diesel',
        TRAVEL_PREVIOUS_DATE_LABEL: 'fecha anterior',
        TRAVEL_STATUS_OPEN: 'No iniciado',
        TRAVEL_STATUS_CLOSED: 'Terminado',
        PASS_CHANGE_STATUS_LABEL: 'cambiar estado',
        PASS_CHANGE_DRIVER_LABEL: 'cambiar de chofer',
        PASS_CHANGE_BUS_LABEL: 'cambiar de camion',
        PASSES: 'asistencia de flotilla',
        PASS_CHANGE_STATUS_PERMISSION: 'cambiar el estado del pase',
        PASS_CHANGE_DRIVER_PERMISSION: 'cambiar el chofer del pase',
        PASS_CHANGE_BUS_PERMISSION: 'cambiar el camion del pase',
        CHANGE_BUS_STATUS: 'Cambio de camion en el pase',
        CHANGE_DRIVER_STATUS: 'Cambio de chofer en el pase',
        CHANGE_PASS_STATUS: 'Cambio manual del estado del pase',
        CHANGED_TO_NEXT_STATUS: 'Cambio al siguiente estado de pase',
        LOG_CHANGE_BUS_STATUS_LABEL: 'cambio de camion en el pase',
        LOG_CHANGE_DRIVER_STATUS_LABEL: 'cambio de chofer en el pase',
        LOG_CHANGE_PASS_STATUS_LABEL: 'cambio de estatus manual en el pase',
        LOG_CHANGED_TO_NEXT_STATUS_LABEL: 'cambio al siguiente estado del pase',
        USER_DEFAULT_PAGE_LABEL: 'pagina inicial del usuario',
        // defaults
        USER_HAS_NO_PERMISSION: 'El usuario no tiene permiso para acceder a esta opcion',
        HOME_PAGE: 'Pagina de inicio',
        HOME_PAGE_MESSAGE: 'Pagina inicial por defecto, seleccione algun elemento del menu para iniciar.',
        LOGIN_PAGE_TITLE: 'iniciar sesión',
        FULL_NAME_LABEL: 'nombre completo',
        LOGIN_BUTTON_LABEL: 'iniciar sesión',
        SELECT_COMPANY_TITLE: 'seleccione la empresa de trabajo',
        COMPANY_LABEL: 'empresa',
        NO_COMPANY_LABEL: 'no hay empresa abierta',
        CHANGE_COMPANY_LABEL: 'cambiar de empresa',
        LOGOUT_LABEL: 'cerrar sesión',
        REMEMBER_ME_LABEL: 'guardar sesión en este dispositivo',
        PASSWORD_RECOVERY_LINK_LABEL: 'olvido su contraseña?',
        RECOVERY_PAGE_TITLE: 'recuperar contraseña',
        RECOVER_BUTTON_LABEL: 'enviar correo de recuperación',
        SELECT_OPTION_LABEL: '-- seleccione uno --',
        USER_LOGIN_ERROR_MESSAGE: 'ocurrio un error al iniciar sesión, por favor verifique sus datos e intente nuevamente.',
        RECOVERY_MAIL_SENT_SUCCESS_MESSAGE: 'el correo de recuperación ha sido enviado.',
        RESET_PASSWORD_PAGE_TITLE: 'defina su nueva contraseña.',
        RESET_PASSWORD_BUTTON_LABEL: 'reestablecer contraseña.',
        RESET_MAIL_SUCCESS_MESSAGE: 'su contraña ha sido redefinida.',
        USER_LOGIN_SUCCESS_MESSAGE: 'bienvenido {username}',
        RESET_MAIL_ERROR_MESSAGE: 'ocurrio un error al redefinir su contraseña verifique que el correo de reestablecimiento siga vigente o genere una nueva solicitud de cambio.',
        MENU_LABEL: 'menu',
        CATALOGS: 'catalogos',
        ALL_RESULTS_PER_PAGE: 'todos los resultados',
        GRID_ROW_TOTAL: 'página {page} de {totalpages} - mostrando {size} registros por página',
        GRID_ALL_ROWS_TOTAL: 'mostrando todos los resultados disponibles',
        CATALOG_LOADING_LABEL: 'cargando {catalog}',
        MALE_GENDER: 'el',
        FEMININE_GENDER: 'la',
        LIST_PAGE_TITLE: 'listado de {catalog}',
        REGISTER_PAGE_TITLE: 'registrar {catalog}',
        EDIT_PAGE_TITLE: 'editar {catalog}',
        FILE: 'archivo',
        FILES: 'archivos',
        FILE_MODAL_TITLE: 'archivos',
        FILE_UPLOAD_LABEL: 'cargar archivo',
        FILE_LIST_TO_EXCEL_LABEL: 'bajar el listado de archivos a excel',
        FILE_DOWNLOAD_ALL_LABEL: 'descargar todo lo seleccionado',
        FILE_NAME_LABEL: 'nombre del archivo',
        FILE_TYPE_LABEL: 'tipo de archivo',
        FILE_SIZE_LABEL: 'tamaño del archivo',
        FILE_DELETE_ALL_LABEL: 'eliminar todo lo seleccionado',
        FILE_DOWNLOAD_LABEL: 'descargar archivo',
        FILE_DELETE_LABEL: 'eliminar archivo',
        FILE_UPLOADED_SUCCESS: 'archivo {file} ha sido cargado.',
        FILE_CONFIRM_SELECTION_LABEL: 'confirmar selección',
        FILE_SELECTED_LABEL: '{count} archivos seleccionados',
        LIST_ENABLED_LABEL: 'habilitado',
        LIST_OPTIONS_LABEL: 'opciones',
        LIST_ADD_LABEL: 'nuevo {catalog}',
        LIST_REFRESH_LABEL: 'refrescar',
        LIST_FILTER_CLEAR_LABEL: 'limpiar filtro',
        LIST_EXCEL_DOWNLOAD_LABEL: 'exportar a excel',
        LIST_EDIT_LABEL: 'editar',
        LIST_DELETE_LABEL: 'eliminar',
        LIST_ENABLE_LABEL: 'habilitar',
        LIST_DISABLE_LABEL: 'deshabilitar',
        LIST_LOADING_LABEL: 'cargando {catalog}',
        LIST_SEARCH_LABEL: 'buscar {catalog}',
        LIST_FILES_LABEL: 'archivos',
        LIST_ALL_OPTIONS_LABEL: 'todas',
        LIST_CONFIRM_DELETE: 'desea eliminar {gender} {catalog} "{identifier}"?',
        LIST_CONFIRM_ENABLE: 'desea habilitar {gender} {catalog} "{identifier}"?',
        LIST_CONFIRM_DISABLE: 'desea deshabilitar {gender} {catalog} "{identifier}"?',
        LIST_DELETE_OK: '{gender} {catalog} {identifier} ha sido eliminado.',
        LIST_DISABLE_OK: '{gender} {catalog} {identifier} ha sido deshabilitado.',
        LIST_ENABLE_OK: '{gender} {catalog} {identifier} ha sido habilitado.',
        LIST_DELETE_ERROR: 'ocurre un error al eliminar {gender} {catalog} {identifier}.',
        LIST_ENABLE_ERROR: 'ocurre un error al habilitar {gender} {catalog} {identifier}.',
        LIST_DISABLE_ERROR: 'ocurre un error al deshabilitar {gender} {catalog} {identifier}.',
        LIST_MESSAGE_CLOSING_LABEL: 'este mensaje se cerrara en {life} segundos',
        PAGINATION_RESULTS_PER_PAGE: '{size} resultados por página',
        FILTER_SEARCH_LABEL: 'buscar {catalog}',
        FILTER_ALL_LABEL: 'tod{gender}s l{gender}s {catalog}',
        FILTER_ACTIVE_LABEL: 'tod{gender}s l{gender}s activ{gender}s',
        FILTER_INACTIVE_LABEL: 'tod{gender}s l{gender}s inactiv{gender}s',
        SEARCH_FROM_DATE_LABEL: 'desde la fecha',
        SEARCH_TO_DATE_LABEL: 'hasta la fecha',
        FORMS_GENERAL_DATA_TITLE: 'datos generales',
        FORMS_SAVE_LABEL: 'guardar',
        FORMS_SAVING_LABEL: 'guardando...',
        FORMS_ERROR_CREATING: 'error al registrar {gender} {catalog}',
        FORMS_ERROR_UPDATING: 'error al registrar {gender} {catalog}',
        FORMS_FILE_UPLOAD_LABEL: 'seleccionar archivo',
        FORMS_GENDER_MALE_LABEL: 'masculino',
        FORMS_GENDER_FEMALE_LABEL: 'femenino',
        FORMS_ERROR_FIELD_REQUIRED: 'el campo {field} es requerido.',
        FORMS_ERROR_FIELD_EMAIL: 'el campo {field} es debe ser un correo electrónico valido.',
        FORMS_ERROR_FIELD_ISUNIQUE: 'el campo {field} es debe ser unico.',
        FORMS_ERROR_FIELD_MINLENGTH: 'el campo {field} debe tener una longitud minima de {min} caracteres.',
        FORMS_ERROR_FIELD_MAXLENGTH: 'el campo {field} debe tener una longitud maxima de {max} caracteres.',
        FORMS_ERROR_FIELD_SAMEAS: 'el campo "{field}" no coincide con el campo "{field2}".',
        FORMS_MULTI_SELECT_LABEL: 'seleccionar {catalog}',
        FORM_EVENT_UPDATED: '{catalog} actualizado.',
        FORM_EVENT_CREATED: '{catalog} registrado',
        PROFILE: 'perfil',
        PROFILES: 'perfiles',
        PROFILE_NAME_LABEL: 'nombre del perfil',
        PROFILE_LABEL: 'ver perfil',
        PROFILE_NO_USER_LABEL: 'no hay usuario?',
        PROFILE_PAGE_TITLE: 'perfil del usuario',
        PROFILE_USER_LABEL: 'perfil del usuario "{username}"',
        PROFILE_USER_PERMISSIONS_LABEL: 'permisos asignados al usuario "{username}"',
        PROFILE_USER_PROFILES_LABEL: 'perfiles asignados al usuario "{username}"',
        PROFILE_NO_PERMISSIONS_LABEL: 'el usuario {username} no tiene permisos asignados',
        PROFILE_NO_PROFILES_LABEL: 'el usuario {username} no tiene perfiles asignados',
        PROFILE_ROOT_PERMISSIONS_LABEL: 'el usuario administrador tiene todos los permisos, no es necesario asignar permisos adicionales',
        PROFILE_ROOT_PROFILES_LABEL: 'el usuario administrador tiene el perfil mas alto del sistema, otros perfiles son innecesarios',
        PROFILE_USER_LOADING_LABEL: 'cargando perfil de usuario',
        USER: 'usuario',
        USERS: 'usuarios',
        USER_USERNAME_LABEL: 'usuario',
        USER_NAME_LABEL: 'nombre',
        USER_LAST_NAME_LABEL: 'apellidos',
        USER_EMAIL_LABEL: 'correo electrónico',
        USER_PASSWORD_LABEL: 'contraseña',
        USER_FULL_NAME_LABEL: 'nombre completo',
        USER_ENABLED_LABEL: 'usuario habilitado?',
        USER_PASSWORD_VERIFICATION_LABEL: 'verificar la contraseña',
        USER_GENDER_LABEL: 'sexo',
        USER_AVATAR_IMG_LABEL: 'avatar usuario',
        USER_ERROR_CHANGING_PASSWORD: 'error al cambiar la contraseña, por favor verifique su información e intente nuevamente.',
        USER_PERMISSIONS_LABEL: 'permisos del usuario',
        ROOT_PERMISSIONS_LABEL: 'este usuario es el administrador del sistema, tiene todos los permisos',
        NO_PERMISSIONS_LABEL: 'este usuario no tiene permisos adicionales asignados.',
        USER_PROFILES_LABEL: 'perfiles del usuario',
        ROOT_PROFILES_LABEL: 'este usuario tiene el perfil mas alto del sistema.',
        NO_PROFILES_LABEL: 'este usuario no tiene perfil(es) asignado(s).',
        SESSION: 'sesion',
        SESSIONS: 'sesiones',
        SESSION_IP_LABEL: 'ip',
        SESSION_TYPE_LABEL: 'tipo de sesión',
        SESSION_USERNAME_LABEL: 'usuario',
        SESSION_USER_AGENT_LABEL: 'navegador',
        SESSION_EXPIRATION_LABEL: 'fecha expiración',
        ACCESS_TOKEN_LABEL: 'sesión normal',
        PASSWORD_RECOVERY_TOKEN_LABEL: 'sesión para reestablecer contraseña',
        LOG: 'bitacora',
        LOGS: 'bitacoras',
        LOG_DATE_LABEL: 'fecha',
        LOG_ALL_CATALOGS: 'todos los catalogos',
        LOG_CATALOG_LABEL: 'catalogo/modulo',
        LOG_OPERATION_LABEL: 'operación',
        LOG_DATA_LABEL: 'datos o identificador',
        LOG_ALL_OPERATIONS_LABEL: 'todas las operaciones',
        LOG_CREATED_LABEL: 'registro',
        LOG_UPDATED_LABEL: 'actualización',
        LOG_ENABLED_LABEL: 'habilitado',
        LOG_DISABLED_LABEL: 'deshabilitado',
        LOG_USERNAME_LABEL: 'usuario',
        LOG_DELETED_LABEL: 'eliminado',
        LOG_PASSWORD_CHANGE_LABEL: 'cambio de contraseña',
        USER_PASSWORD_CHANGE_LABEL: 'cambiar contraseña',
        USER_CHANGE_PASSWORD: 'cambiar la contraseña del usuario "{username}"',
        USER_PASSWORD_CHANGED: 'la contraseña del usuario "{username}" ha sido actualizada',
        PERMISSION: 'permiso',
        PERMISSIONS: 'permisos',
        PERMISSION_PERMISSION_CATEGORY: 'permisos',
        PERMISSION_CATEGORY_LABEL: 'categoria',
        PERMISSION_AUTHORITIES_LABEL: 'descripción',
        PROFILE_PERMISSION_CATEGORY: 'perfiles',
        USER_PERMISSION_CATEGORY: 'usuarios',
        SESSION_PERMISSION_CATEGORY: 'sesiones',
        PERMISSION_LIST_ALL_PERMISSION: 'permiso para consultar todos los permisos disponibles.',
        PROFILE_CREATE_PERMISSION: 'permiso para crear nuevos perfiles',
        PROFILE_DELETE_PERMISSION: 'permiso para borrar perfiles permanentemente',
        PROFILE_DISABLE_PERMISSION: 'permiso para deshabilitar perfiles',
        PROFILE_ENABLE_PERMISSION: 'permiso para habilitar perfiles',
        PROFILE_LIST_ALL_PERMISSION: 'permiso acceder a la pagina de permisos',
        PROFILE_UPDATE_PERMISSION: 'permiso para actualizar perfiles',
        SESSION_DELETE_PERMISSION: 'permiso para cerrar sesiones de cualquier usuario.',
        SESSION_LIST_ALL_PERMISSION: 'permiso para acceder a la pagina de sesiones',
        USER_CREATE_PERMISSION: 'permiso para crear nuevos usuarios',
        USER_DELETE_PERMISSION: 'permiso para eliminar permanentemente usuarios',
        USER_DETAIL_PERMISSION: 'permiso para ver los detalles de los usuarios',
        USER_DISABLE_PERMISSION: 'permiso para deshabilitar usuarios',
        USER_ENABLE_PERMISSION: 'permiso para habilitar usuarios',
        USER_LIST_ALL_PERMISSION: 'permiso para acceder a la pagina de usuarios',
        USER_PASSWORD_CHANGE_PERMISSION: 'permiso para cambiar la contraseña de cualquier usuario',
        USER_PASSWORD_RECOVERY_PERMISSION: 'permiso para enviar el correo de recuperación de la contraseña de cualquier usuario',
        USER_UPDATE_PERMISSION: 'permiso para editar usuarios',
        TRAVEL_TYPE: 'tipo de viaje',
        TRAVEL_TYPES: 'tipos de viaje',
        TRAVEL_TYPE_CREATE_PERMISSION: 'permiso para agregar nuevos tipos de viaje',
        TRAVEL_TYPE_UPDATE_PERMISSION: 'permiso para editar tipos de viaje',
        TRAVEL_TYPE_DELETE_PERMISSION: 'permiso para eliminar tipos de viaje',
        TRAVEL_TYPE_DISABLE_PERMISSION: 'permiso para deshabilitar tipos de viaje',
        TRAVEL_TYPE_ENABLE_PERMISSION: 'permiso para habilitar tipos de viaje',
        TRAVEL_TYPE_LIST_ALL_PERMISSION: 'permiso para listar todos los tipos de viaje',
        TRAVEL_TYPE_NAME_LABEL: 'nombre',
        TURN: 'turno',
        TURNS: 'turnos',
        TURN_CREATE_PERMISSION: 'permiso para agregar nuevos turnos',
        TURN_UPDATE_PERMISSION: 'permiso para editar turnos',
        TURN_DELETE_PERMISSION: 'permiso para eliminar turnos',
        TURN_DISABLE_PERMISSION: 'permiso para deshabilitar turnos',
        TURN_ENABLE_PERMISSION: 'permiso para habilitar turnos',
        TURN_LIST_ALL_PERMISSION: 'permiso para listar todos los turnos',
        TURN_NAME_LABEL: 'nombre',
        TURN_ACCOUNT_PREVIOUS_DAY_LABEL: 'contabilizar dia anterior',
        ROUTE: 'recorrido',
        ROUTES: 'recorridos',
        ROUTE_CREATE_PERMISSION: 'permiso para agregar nuevos recorridos',
        ROUTE_UPDATE_PERMISSION: 'permiso para editar recorridos',
        ROUTE_DELETE_PERMISSION: 'permiso para eliminar recorridos',
        ROUTE_DISABLE_PERMISSION: 'permiso para deshabilitar recorridos',
        ROUTE_ENABLE_PERMISSION: 'permiso para habilitar recorridos',
        ROUTE_LIST_ALL_PERMISSION: 'permiso para listar todos los recorridos',
        ROUTE_NAME_LABEL: 'recorrido',
        ROUTE_DESCRIPTION_LABEL: 'descripcion',
        ROUTE_ACCOUNTING_LABEL: 'contabilizar',
        ROUTE_MAP_LABEL: 'mapa',
        DRIVER: 'chofer',
        DRIVERS: 'choferes',
        DRIVER_CREATE_PERMISSION: 'permiso para agregar nuevos choferes',
        DRIVER_UPDATE_PERMISSION: 'permiso para editar choferes',
        DRIVER_DELETE_PERMISSION: 'permiso para eliminar choferes',
        DRIVER_DISABLE_PERMISSION: 'permiso para deshabilitar choferes',
        DRIVER_ENABLE_PERMISSION: 'permiso para habilitar choferes',
        DRIVER_LIST_ALL_PERMISSION: 'permiso para listar todos los choferes',
        DRIVER_PHOTO_LABEL: 'foto',
        DRIVER_EMPLOYEE_NUMBER_LABEL: 'numero',
        DRIVER_NAME_LABEL: 'nombre',
        DRIVER_DATE_OF_BIRTH_LABEL: 'fecha de nacimiento',
        DRIVER_NATIONALITY_LABEL: 'nacionalidad',
        DRIVER_FATHER_LAST_NAME_LABEL: 'apellido paterno',
        DRIVER_PLACE_OF_BIRTH_LABEL: 'lugar de nacimiento',
        DRIVER_SEX_LABEL: 'sexo',
        DRIVER_MOTHER_LAST_NAME_LABEL: 'apellido materno',
        DRIVER_CIVIL_STATUS_LABEL: 'estado civil',
        DRIVER_PHONE_LABEL: 'telefono',
        DRIVER_ADDRESS_LABEL: 'domicilio',
        DRIVER_STREET2_LABEL: 'colonia',
        DRIVER_ZIP_CODE_LABEL: 'codigo postal',
        DRIVER_CURP_LABEL: 'curp',
        DRIVER_LICENSE_LABEL: 'licencia',
        DRIVER_ID_NUMBER_LABEL: 'numero id',
        DRIVER_DRIVER_TYPE_LABEL: 'tipo de chofer',
        DRIVER_RFC_LABEL: 'r.f.c.',
        DRIVER_EXPEDITION_DATE_LABEL: 'fecha de expedicion',
        DRIVER_NSS_LABEL: 'numero de seguro social',
        DRIVER_PAYMENT_TYPE_LABEL: 'tipo de pago',
        DRIVER_DUE_DATE_LABEL: 'fecha de vencimiento',
        DRIVER_POSITION_LABEL: 'puesto',
        DRIVER_DOCUMENT1_LABEL: 'ife/ine',
        DRIVER_DOCUMENT2_LABEL: 'carta de no antecedentes',
        DRIVER_DOCUMENT3_LABEL: 'targeton',
        DRIVER_DOCUMENT4_LABEL: 'comprobante de domicilio',
        DRIVER_DOCUMENT5_LABEL: 'estudio socioeconomico',
        DRIVER_DOCUMENT6_LABEL: 'comprobante de estudios',
        DRIVER_DOCUMENT7_LABEL: 'antidoping',
        DRIVER_DOCUMENT8_LABEL: 'acata de nacimiento',
        DRIVER_DOCUMENT9_LABEL: 'carta anti estres',
        DRIVER_DOCUMENT10_LABEL: 'contrato firmado',
        DRIVER_CRONIC_DESEASE_LABEL: 'padese alguna enfermedad cronica',
        DRIVER_IN_DATE_LABEL: 'fecha de alta',
        DRIVER_OUT_DATE_LABEL: 'fecha de baja',
        BUS: 'camion',
        BUSES: 'camiones',
        BUS_CREATE_PERMISSION: 'permiso para agregar nuevos camiones',
        BUS_UPDATE_PERMISSION: 'permiso para editar camiones',
        BUS_DELETE_PERMISSION: 'permiso para eliminar camiones',
        BUS_DISABLE_PERMISSION: 'permiso para deshabilitar camiones',
        BUS_ENABLE_PERMISSION: 'permiso para habilitar camiones',
        BUS_LIST_ALL_PERMISSION: 'permiso para listar todos los camiones',
        BUS_PHOTO_LABEL: 'fotografia frontal de la unidad',
        BUS_FLOAT_NUMBER_LABEL: 'no. flotilla',
        BUS_SERIES_LABEL: 'no. serie',
        BUS_PLATES_LABEL: 'placas',
        BUS_ECONOMIC_LABEL: 'no. economico',
        BUS_MODEL_LABEL: 'modelo',
        BUS_POLIZA_LABEL: 'poliza',
        BUS_ROUTE_NO_LABEL: 'no. ruta',
        BUS_SEASON_MAINTENANCE_LABEL: 'mantenimiento de temporada',
        BUS_PREVENTIVE_MAINTENANCE_LABEL: 'mantenimiento preventivo',
        BUS_GPS_LABEL: 'gps',
        BUS_REVALIDATION_LABEL: 'revalidacion vehicular',
        BUS_BRAND_LABEL: 'marca',
        BUS_TUNE_UP_LABEL: 'tune-up',
        BUS_ENGOMADO_LABEL: 'engomado ecologico',
        BUS_YEAR_LABEL: 'año',
        BUS_TYPE_LABEL: 'tipo de camion',
        BUS_ENGINE_LABEL: 'motor',
        BUS_COMMENTS_LABEL: 'comentarios',
        BUS_LEFT_LABEL: 'lateral izquierdo',
        BUS_RIGHT_LABEL: 'lateral derecho',
        BUS_BACK_LABEL: 'trasera',
        BUS_BACK_INTERNAL_LABEL: 'interna trasera',
        BUS_FRONT_INTERNAL_LABEL: 'interna delantera',
        BUS_BATTERIES_LABEL: 'pilas',
        COMPANY: 'empresa',
        COMPANIES: 'empresas',
        COMPANY_CREATE_PERMISSION: 'permiso para agregar empresas',
        COMPANY_UPDATE_PERMISSION: 'permiso para editar empresas',
        COMPANY_DELETE_PERMISSION: 'permiso para eliminar empresas',
        COMPANY_DISABLE_PERMISSION: 'permiso para deshabilitar empresas',
        COMPANY_ENABLE_PERMISSION: 'permiso para habilitar empresas',
        COMPANY_LIST_ALL_PERMISSION: 'permiso para listar todas las empresas',
        COMPANY_NAME_LABEL: 'nombre',
        COMPANY_RAZON_SOCIAL_LABEL: 'razon social',
        COMPANY_RFC_LABEL: 'rfc',
        COMPANY_ADDRESS_LABEL: 'direccion',
        DIESEL_CONTROL: 'control de diesel',
        DIESEL_CONTROLS: 'controles de diesel',
        DIESEL_CONTROL_CREATE_PERMISSION: 'permiso para agregar nuevo contro de diesel',
        DIESEL_CONTROL_UPDATE_PERMISSION: 'permiso para editar control de diesel',
        DIESEL_CONTROL_DELETE_PERMISSION: 'permiso para eliminar control de diesel',
        DIESEL_CONTROL_DISABLE_PERMISSION: 'permiso para deshabilitar control de diesel',
        DIESEL_CONTROL_ENABLE_PERMISSION: 'permiso para habilitar control de diesel',
        DIESEL_CONTROL_LIST_ALL_PERMISSION: 'permiso para listar todos los controles de diesel',
        DIESEL_CONTROL_TRAVEL_COUNT_LABEL: 'viajes',
        DIESEL_CONTROL_POINT_OF_REFUEL_LABEL: 'punto de recarga',
        TRAVEL: 'programacion de viajes',
        TRAVELS: 'programaciones de viajes',
        PASS: 'asistencia de flotilla',
        PASS_COMMENT_LABEL: 'comentarios',
        PASS_STATUS_CHANGE_TITLE: 'cambiar estado',
        PASS_BUS_CHANGE_TITLE: 'cambiar camion',
        PASS_DRIVER_CHANGE_TITLE: 'cambiar chofer',
        PASS_STATUS_OK_CHANGED: 'el estado del pase ha sido cambiado',
        PASS_DRIVER_OK_CHANGED: 'el chofer del pase ha sido cambiado',
        PASS_BUS_OK_CHANGED: 'el camion del pase ha sido cambiado',
        ERROR_CHANGING_PASS: 'ocurrio un error al realizar los cambios al pase',
        PASS_STATUS_LABEL: 'estado',
        PASS_BUS_LABEL: 'camion',
        PASS_DRIVER_LABEL: 'chofer',
        TRAVEL_CREATE_PERMISSION: 'permiso para agregar nueva programacion de viaje',
        TRAVEL_UPDATE_PERMISSION: 'permiso para editar programacion de viaje',
        TRAVEL_DELETE_PERMISSION: 'permiso para eliminar programacion de viaje',
        TRAVEL_DISABLE_PERMISSION: 'permiso para deshabilitar programacion de viaje',
        TRAVEL_ENABLE_PERMISSION: 'permiso para habilitar programacion de viaje',
        TRAVEL_LIST_ALL_PERMISSION: 'permiso para listar todas las programaciones de viaje',
        TRAVEL_DATE_LABEL: 'fecha',
        TRAVEL_ACCOUNT_DAY_BEFORE_LABEL: 'contabilizar dia anterior',
        TRAVEL_FROM_LABEL: 'desde',
        TRAVEL_TO_LABEL: 'hasta',
        TRAVEL_SATURDAY_LABEL: 'sabado',
        TRAVEL_SUNDAY_LABEL: 'domingo'
    },
};

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: navigator.language.split('-')[0],
    fallbackLocale: 'es',
    messages,
});

export default i18n;
