import Token from "@/token";

const Menu = {
    _items: [],

    get items() {
        this._items = [
            { id: 1, name: 'MODULES', items: [] },
            { id: 2, name: 'REPORTS', items: [] },
            { id: 3, name: 'CATALOGS', items: [] }
        ]

        if(Token.isAuthenticated()) {
            if (Token.hasPermission('TRAVEL_PROGRAMMING_LIST_ALL_PERMISSION')) {
                this._items[0].items.push({
                    order: 1,
                    name: 'TRAVEL_PROGRAMMING_LIST_PAGE_TITLE',
                    icon: 'key',
                    label: 'TRAVEL'
                });
            }
            if (Token.hasPermission('DIESEL_CONTROL_LIST_ALL_PERMISSION')) {
                this._items[0].items.push({
                    order: 2,
                    name: 'DIESEL_CONTROL_LIST_PAGE_TITLE',
                    icon: 'key',
                    label: 'DIESEL_CONTROL'
                });
            }
            if (Token.hasPermission('TRAVEL_LIST_ALL_PERMISSION')) {
                this._items[0].items.push({
                    order: 3,
                    name: 'TRAVEL_LIST_PAGE_TITLE',
                    icon: 'key',
                    label: 'PASS'
                });
            }

            if (Token.hasPermission('PAYROLL_REPORT_PERMISSION')) {
                this._items[1].items.push({
                    order: 1,
                    name: 'PAYROLL_REPORT_PAGE_TITLE',
                    icon: 'key',
                    label: 'PAYROLL_REPORT'
                });
            }
            if (Token.hasPermission('COLLECTION_REPORT_PERMISSION')) {
                this._items[1].items.push({
                    order: 2,
                    name: 'COLLECTION_REPORT_PAGE_TITLE',
                    icon: 'key',
                    label: 'COLLECTION_REPORT'
                });
            }
            if (Token.hasPermission('BUS_REPORT_PERMISSION')) {
                this._items[1].items.push({
                    order: 3,
                    name: 'BUS_REPORT_PAGE_TITLE',
                    icon: 'key',
                    label: 'BUS_REPORT'
                });
            }
            if (Token.hasPermission('LOG_REPORT_PERMISSION')) {
                this._items[1].items.push({
                    order: 4,
                    name: 'LOG_LIST_PAGE_TITLE',
                    icon: 'key',
                    label: 'LOG'
                });
            }

            if (Token.hasPermission('USER_LIST_ALL_PERMISSION')) {
                this._items[2].items.push({
                    order: 1,
                    name: 'USER_LIST_PAGE_TITLE',
                    icon: 'person',
                    label: 'USERS',
                    permission: '',
                },);
            }
            if (Token.hasPermission('SESSION_LIST_ALL_PERMISSION')) {
                this._items[2].items.push({
                    order: 2,
                    name: 'SESSION_LIST_PAGE_TITLE',
                    icon: 'key',
                    label: 'SESSIONS'
                });
            }
            if (Token.hasPermission('PROFILE_LIST_ALL_PERMISSION')) {
                this._items[2].items.push({
                    order: 3,
                    name: 'PROFILE_LIST_PAGE_TITLE',
                    icon: 'file-earmark-person',
                    label: 'PROFILES'
                });
            }
            if (Token.hasPermission('COMPANY_LIST_ALL_PERMISSION')) {
                this._items[2].items.push({
                    order: 4,
                    name: 'COMPANY_LIST_PAGE_TITLE',
                    icon: 'key',
                    label: 'COMPANY'
                });
            }
            if (Token.hasPermission('BUS_LIST_ALL_PERMISSION')) {
                this._items[2].items.push({
                    order: 5,
                    name: 'BUS_LIST_PAGE_TITLE',
                    icon: 'key',
                    label: 'BUSES'
                },);
            }
            if (Token.hasPermission('DRIVER_LIST_ALL_PERMISSION')) {
                this._items[2].items.push({
                    order: 6,
                    name: 'DRIVER_LIST_PAGE_TITLE',
                    icon: 'key',
                    label: 'DRIVERS'
                });
            }
            if (Token.hasPermission('ROUTE_LIST_ALL_PERMISSION')) {
                this._items[2].items.push({
                    order: 7,
                    name: 'ROUTE_LIST_PAGE_TITLE',
                    icon: 'key',
                    label: 'ROUTES'
                });
            }
            if (Token.hasPermission('TURN_LIST_ALL_PERMISSION')) {
                this._items[2].items.push({
                    order: 8,
                    name: 'TURN_LIST_PAGE_TITLE',
                    icon: 'key',
                    label: 'TURNS'
                });
            }
            if (Token.hasPermission('TRAVEL_TYPE_LIST_ALL_PERMISSION')) {
                this._items[2].items.push({
                    order: 9,
                    name: 'TRAVEL_TYPE_LIST_PAGE_TITLE',
                    icon: 'key',
                    label: 'TRAVEL_TYPES'
                });
            }

            const modules = this._items.map(x => x.name).indexOf('MODULES');
            if (this._items[modules].items.length <= 0) {
                this._items.splice(modules, 1);
            }

            const reports = this._items.map(x => x.name).indexOf('REPORTS');
            if (this._items[reports].items.length <= 0) {
                this._items.splice(reports, 1);
            }

            const catalogs = this._items.map(x => x.name).indexOf('CATALOGS');
            if (this._items[catalogs].items.length <= 0) {
                this._items.splice(catalogs, 1);
            }

            return this._items;
        } else {
            return [];
        }
    }
};
export default Menu;
