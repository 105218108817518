<template>
  <div>
    <b-alert :variant="message.type" v-for="message in messages.filter(x => x.type === 'danger')" :key="message.id" @dismissed="remove(message.id)" show
             dismissible>
      {{ ($t(message.message, message.params) + ' ' + $t('LIST_MESSAGE_CLOSING_LABEL', {life: message.life})) | capitalize }}
      <pre v-if="message.error">
        {{ message.error }}
      </pre>
    </b-alert>
  </div>
</template>

<script>
import eventBus from "@/events";
import Constants from "@/constants";
import { v4 as uuidv4 } from 'uuid';
import {CapitalFilter} from "@/mixins";

export default {
  name: "AppMessages",
  mixins: [CapitalFilter],
  data() {
    return {
      messages: [],
      messageCount: 0
    }
  },
  mounted() {
    this.messages = [];
    this.messageCount = 0;
    eventBus.$on(Constants.EVENT_WHEN_MESSAGE_DISPLAY, (message, params, type, error) => {
      this.messages.push({id: uuidv4(), message, params, type, error, life: 10});
      this.messageCount++;
    });

    setInterval(() => {
      let toRemove = [];
      this.messages.forEach(m => {
        if (m.life > 0) {
          m.life--;
        }
        if (m.life === 0) {
          toRemove.push(this.messages.map(x => x.id));
        }
      });
      if(toRemove.length > 0) {
        toRemove.forEach(idx => {
          this.messages.splice(idx, 1);
        });
      }
    }, 1000);
  },
  methods: {
    remove(message) {
      const idx = this.messages.map(m => m.id).indexOf(message);
      if (idx !== -1) {
        this.messages.splice(idx, 1);
      }
    }
  }
}
</script>

<style scoped>

</style>
